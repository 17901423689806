<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */
    import BaseStore from "../models/BaseStore";
    import Vendor from "../models/Vendor";
    import map from "lodash/map";

    export default {
        name: "VendorPicker",
        props: {
            value: Array
        },
        data() {
            return {
                internalValue: this.value,
                store: new BaseStore([], {
                    model: Vendor,
                    sorter: {
                        property: 'name',
                        direction: BaseStore.SORT_DESC
                    },
                    fetchParams: {
                        limit: 0,
                        fields: 'id,name'
                    }
                })
            }
        },
        computed: {
            options: vm => map(vm.store.models, m => ({label: m.name, value: m.id})),
            loading: vm => vm.store.loading
        },
        watch: {
            value(value) {
                this.internalValue = value;
            },
            internalValue: function () {
                this.$emit("input", this.internalValue);
            }
        },
        methods: {
            async fetchData() {
                try {
                    await this.store.fetch();
                } catch (e) {
                    this.$message.error("Ошибка получения данных по цехам");
                }
            }
        },
        mounted() {
            this.fetchData();
        }
    }
</script>


<template>
    <el-select
            v-model="internalValue"
            v-bind="$attrs"
            placeholder="Название..."
            clearable
            :loading="loading">
        <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
        </el-option>
    </el-select>
</template>


<style lang="scss" scoped>
    .el-select-dropdown {
        &__item {
            height: auto;
            padding: 5px 20px;

            .name {
                line-height: 20px;
            }

            .metas {
                line-height: 12px;
                font-size: 12px;
                color: silver;
                font-weight: 300;
            }
        }
    }
</style>