/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import axios from "axios";

export default class ProductCategory extends BaseModel {
    static entityName = 'product-category-image';

    defaults() {
        return {
            id: null,
            src: '',
            title: null,
            display_order: 1,
            product_category_id: null
        }
    }

    static getUploadUrl(params = {expand: 'thumbUrl'}) {
        return `${axios.defaults.baseURL}/${this.urlPrefix}/${this.entityName}/upload?` + (new URLSearchParams(params)).toString();
    }

    static getUploadHeaders() {
        return axios.defaults.headers.common;
    }

}
