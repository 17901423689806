<script>
/**
 *  Тулбар для списка Продуктов
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "../../models/BaseStore";
import StoreFiltersPane from "../../components/StoreFiltersPane";
import OrderStatusPicker from "../order/StatusPicker";
import VendorPicker from "../../components/VendorPicker";


export default {
  name: "ListFilters",
  components: {VendorPicker, OrderStatusPicker, StoreFiltersPane},
  props: {
    store: {
      type: BaseStore,
      require: true
    }
  },
  data() {
    return {
      sort: null,
      vendorIDs: [],
      statuses: [],
      sortOptions: [
        {
          id: 'created_desc',
          title: 'Недавно созданные',
          property: 'created_at',
          direction: BaseStore.SORT_DESC
        },
        {
          id: 'updated_desc',
          title: 'Недавно измененные',
          property: 'updated_at',
          direction: BaseStore.SORT_DESC
        }
      ]
    }
  },
  watch: {
    vendorIDs(ids) {
      this.store.applyFilter('vendors', {
        property: 'vendor_id',
        operator: 'in',
        value: ids
      });
      this.store.reload();
    },
    statuses(statuses) {
      this.store.applyFilter('statuses', {
        property: 'status',
        operator: 'in',
        value: statuses
      });
    }
  },
  methods: {
    applyCurrentFilters() {
      let filters = this.store.getFilters();
      this.vendorIDs = filters['vendorIDs'] && filters['vendorIDs'].value || [];
      this.statuses = filters['statuses'] && filters['statuses'].value || [];
    }
  },
  created() {
    this.applyCurrentFilters();
    this.store.on(BaseStore.EVENT_FILTERSDROP, this.applyCurrentFilters);
  }
}
</script>


<template>
  <div class="toolbar">
    <store-filters-pane :store="store"
                        @reset="">
      <template v-slot:default>
        <el-form label-position="top"
                 ref="filtersForm"
                 class="content">

          <el-form-item label="Цеха">
            <vendor-picker v-model="vendorIDs"
                           multiple
                           :collapse-tags="false"
                           placeholder="Все цеха..."
                           clearable>
            </vendor-picker>
          </el-form-item>
        </el-form>
      </template>
    </store-filters-pane>
  </div>
</template>


<style scoped>
.toolbar {
  margin: 0;
}
</style>