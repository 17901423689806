<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import ProductProperty from "../../../models/ProductProperty";


    export default {
        name: "Form",

        props: {
            model: {
                type: ProductProperty,
                default: () => new ProductProperty()
            }
        },


        data() {
            return {
                isEdit: this.model.id,
                title: this.model.id ? this.model.$.name : 'Создать свойство'
            }
        },


        methods: {
            back() {
                this.$router.push({name: 'products-properties'});
            },

            async onSaveClick() {
                try {
                    await this.model.save();
                    this.$message.success(`Успешно сохранен ${this.model.name}`);
                    this.back();
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошика сохранения ${this.model.name}`);
                    } else {
                        throw  e;
                    }
                }
            },

            async onDeleteClick() {
                try {
                    await this.model.delete();
                    this.$message.success(`Успешно удалено`);
                    this.back();
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошика удаления ${this.model.name}`);
                    } else {
                        throw  e;
                    }
                }
            }
        }
    }
</script>


<template>
    <div v-loading="model.saving">
        <h1>
            <el-page-header title=""
                            @back="back"
                            :content="title"></el-page-header>
        </h1>

        <el-form style="max-width: 500px;">
            <el-form-item label="Название"
                          :error="model.errors.name"
                          :show-message="!!model.errors.name"
                          required="">
                <el-input v-model="model.name"
                          placeholder="например, Цвет или Объем..."
                          :maxlength="120"></el-input>
            </el-form-item>

            <div class="toolbar">
                <el-button type="primary"
                           @click="onSaveClick">{{isEdit ? 'Изменить' : 'Создать'}}
                </el-button>

                <el-popconfirm @confirm="onDeleteClick"
                               v-if="isEdit"
                               confirmButtonText='Да'
                               cancelButtonText='Отмена'
                               title="Уверены что хотите удалить?">
                    <el-button icon="el-icon-delete"
                               slot="reference"
                               type="danger"></el-button>
                </el-popconfirm>
            </div>
        </el-form>
    </div>
</template>


<style scoped>

</style>