<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../../models/Product";
    import ProductCategory from "../../../models/ProductCategory";
    import SimpleList from "../../../components/SimpleList";

    export default {
        name: "ProductParents",
        components: {
            SimpleList
        },

        props: {
            model: {
                type: Product,
                required: true
            }
        },

        data() {
            return {
                selectedProperties: [],
                store: null,
                filterText: '',
                isDialogOpened: false,
                selectedParents: [],
                availableParents: ProductCategory.getAvailable(),
            }
        },

        watch: {
            model: 'prepareData'
        },

        methods: {
            prepareData() {
                this.store = this.model.getHasParents({
                    autoLoad: true,
                    fetchParams: {
                        limit: 0,
                        expand: 'categoryName'
                    }
                });
            },

            onEditClick(record) {
                this.$prompt("Порядок", record.get("parentCategoryName"), {
                    inputType: 'number',
                    inputValue: record.get("display_order")
                }).then(({value}) => {
                    record.set("display_order", value);
                    record.save().then(() => this.$message.success("Порядок категории успешно изменен"));
                });
            },

            onCommand(command, record) {
                switch (command) {
                    case 'edit':
                        return this.onEditClick(record);
                    case 'redirect':
                        return this.onRedirectClick(record);
                    case 'remove':
                        return this.onRemoveClick(record);
                    case 'makePreferred':
                        return this.onMakePreferredClick(record);
                }
                console.warn("Unknown command");
            },

            onMakePreferredClick(record) {
                this.model.changePreferredRef(record).then(() =>
                    this.$message.success("Основная категория успешно изменена")
                );
            },

            onRedirectClick(record) {
                this.$router.push({
                    name: 'category-basic',
                    params: {
                        id: record.get("category_id")
                    }
                })
            },

            onRemoveClick(record) {
                record.delete().then(() => {
                    this.$message.success("Родитель успешно отвязан");
                    this.store.remove(record);
                });
            },

            onLinkClick() {
                this.isDialogOpened = true;
            },

            async onLinkSubmitClick() {
                try {
                    await this.model.linkParents(this.selectedParents);
                    this.selectedParents = [];
                    this.isDialogOpened = false;
                    this.$message.success("Родители успешно привязаны");
                } catch (e) {
                    console.error(e);
                    this.$message.error("Ошибка операции");
                }
            },

            fetchAvailableCategories(query) {
                // prevent double request on empty
                if (query === '') {
                    return;
                }

                let excludedIds = this.store.map(m => m.category_id);
                this.availableParents = ProductCategory.getAvailable(query, [], excludedIds);
                this.availableParents.fetch();
            },

            getMetaString(record) {
                return `Позиция: ${record.display_order}`;
            },
        },

        created() {
            this.prepareData();
        }
    }
</script>


<template>
    <div v-loading="store.loading"
         class="content">
        <simple-list :store="store"
                     name-prop="categoryName"
                     tools-width="50">

            <template v-slot:item="{record}">
                <div :class="{is_preferred: record.is_preferred}">
                    <div class="item-name text-ellipsis">{{record.categoryName}}</div>
                    <div class="metas">{{ getMetaString(record)}}</div>
                </div>
            </template>

            <template v-slot:toolbar>
                <div class="toolbar-item">
                    <el-button type="primary"
                               icon="el-icon-link"
                               @click="onLinkClick">Привязать
                    </el-button>
                </div>
            </template>

            <template v-slot:tools="{record}">
                <div class="toolbar">
                    <el-dropdown trigger="click"
                                 @command="onCommand($event, record)">
                        <el-button circle
                                   size="medium"
                                   icon="el-icon-more"></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="makePreferred" v-if="!record.is_preferred">Сделать основной</el-dropdown-item>
                            <el-dropdown-item command="edit">Изменить позицию</el-dropdown-item>
                            <el-dropdown-item command="redirect">Перейти в карточку</el-dropdown-item>
                            <el-dropdown-item command="remove">Отвязать</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </template>
        </simple-list>

        <el-dialog :visible.sync="isDialogOpened"
                   title="Привязать к родительским категориям">
            <el-select v-model="selectedParents"
                       remote
                       :remote-method="fetchAvailableCategories"
                       :loading="availableParents.loading"
                       clearable
                       filterable
                       multiple
                       placeholder="Название...">
                <el-option v-for="model in availableParents.models"
                           :key="model.id"
                           :label="model.name"
                           :value="model.id">
                </el-option>
            </el-select>
            <p>
                <el-button @click="onLinkSubmitClick">Привязать</el-button>
            </p>
        </el-dialog>
    </div>
</template>


<style scoped lang="scss">
    .toolbar {
        margin: 0;
    }

    .el-select {
        width: 100%;
    }

    .is_preferred {
        font-weight: bold;
        padding-left: .5rem;
        position: relative;

        &:before {
            content: "*";
            display: inline-block;
            position: absolute;
            left: 0;
        }
    }

</style>