<script>
    /**
     * Filter pane with form and button
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage https://sitespring.ru
     * @licence Proprietary
     */

    import BaseStore from "../models/BaseStore";

    export default {
        name: "StoreFiltersPane",
        props: {
            // store to be filtered
            store: {
                type: BaseStore,
                require: true
            }
        },

        data() {
            return {
                paneTitle: 'Фильтры',
                isPaneOpened: false,
                direction: 'rtl',
                isModal: false, // assume filter pane is not modal
                filtersCount: this.store.getFiltersCount()
            }
        },

        computed: {
            isResetAvailable: vm => vm.filtersCount > 0
        },

        methods: {
            onTriggerClick() {
                this.isPaneOpened = !this.isPaneOpened;
            },
            onStoreFiltersChange() {
                this.filtersCount = this.store.getFiltersCount();
            },
            onResetClick() {
                this.store.dropFilters();
            }
        },

        created() {
            this.store.on(BaseStore.EVENT_FILTERSCHANGE, this.onStoreFiltersChange);
        }
    }
</script>


<template>
    <div class="trigger">
        <el-badge :hidden="filtersCount===0"
                  :value="filtersCount">
            <el-button icon="el-icon-set-up"
                       @click="onTriggerClick">Фильтры
            </el-button>
        </el-badge>

        <el-drawer :modal="isModal"
                   :title="paneTitle"
                   :visible.sync="isPaneOpened"
                   :direction="direction">

            <template v-slot:title>
                <div class="pane-header">
                    <span class="pane-header__title">
                        <el-icon class="el-icon-set-up"></el-icon> Фильтры</span>
                    <el-button type="danger"
                               v-if="isResetAvailable"
                               @click="onResetClick">Сбросить
                    </el-button>
                </div>
            </template>

            <div class="content">
                <slot name="default">
                    <el-form label-position="top"
                             ref="filtersForm"
                             class="content">
                        The filters form goes here...
                    </el-form>
                </slot>
            </div>
        </el-drawer>
    </div>
</template>


<style scoped lang="scss">
    .content {
        padding: 0 20px;
    }

    .pane-header {
        &__title {
            font-weight: bold;
            margin: 0 20px 0 0;
        }
    }
</style>