<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import ProductPropertyCollection from "../../../models/ProductPropertyCollection";
    import PropertiesSelector from "./PropertiesSelector";

    export default {
        name: "Form",

        components: {
            PropertiesSelector
        },

        props: {
            product_property_collection_id: Number | String
        },


        data() {
            return {
                model: new ProductPropertyCollection(),
                isEdit: this.product_property_collection_id
            }
        },


        methods: {
            async prepareData() {
                if (!this.isEdit) {
                    return false;
                }

                this.model.set({
                    id: this.product_property_collection_id
                });

                try {
                    await this.model.fetch({
                        params: {
                            expand: 'batchProperties'
                        }
                    });
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных");
                    throw e;
                }
            },


            async onSaveClick() {
                try {
                    await this.model.save();
                    this.$message.success(`Успешно сохранен ${this.model.name}`);
                    this.back();
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошика сохранения ${this.model.name}`);
                    }
                    throw  e;
                }
            },

            async onDeleteClick() {
                try {
                    await this.model.delete();
                    this.$message.success(`Успешно удалено`);
                    this.back();
                } catch (e) {
                    if (this.model && this.model.fatal) {
                        this.$message.error(`Ошика удаления ${this.model.name}`);
                    } else {
                        throw  e;
                    }
                }
            },

            back() {
                this.$router.push({name: 'products-collections'});
            },
        },


        watch: {
            $route: 'fetchData'
        },


        created() {
            this.prepareData();
        }
    }
</script>


<template>
    <div v-loading="model.saving || model.loading"
         style="max-width: 800px;">
        <h1>
            <el-page-header title=""
                            @back="back"
                            :content="this.model.$.name || 'Создать коллекцию'"></el-page-header>
        </h1>

        <el-form>
            <el-form-item label="Название"
                          :error="model.errors.name"
                          :show-message="!!model.errors.name"
                          required="">
                <el-input v-model="model.name"
                          placeholder="например, Мясные изделия или Сыры..."
                          :maxlength="120"></el-input>
            </el-form-item>
        </el-form>


        <properties-selector v-model="model.batchProperties"></properties-selector>


        <div class="toolbar">
            <el-button type="primary"
                       @click="onSaveClick">{{isEdit ? 'Сохранить' : 'Создать'}}
            </el-button>

            <el-popconfirm @confirm="onDeleteClick"
                           v-if="isEdit"
                           confirmButtonText='Да'
                           cancelButtonText='Отмена'
                           title="Уверены что хотите удалить?">
                <el-button icon="el-icon-delete"
                           slot="reference"
                           type="danger"></el-button>
            </el-popconfirm>
        </div>

    </div>
</template>


<style scoped lang="scss">

    .toolbar {
        margin: 40px 0;

        > * + * {
            margin-left: 20px;
        }
    }


</style>