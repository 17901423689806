/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage https://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class ProductCategoryHasCategory extends BaseModel {
    static entityName = 'product-category-has-category';

    defaults() {
        return {
            parent_category_id: null,
            child_category_id: null,
            display_order: 1
        }
    }

    mutations() {
        return {
            id: (id) => `${this.parent_category_id},${this.child_category_id}`
        };
    }
}
