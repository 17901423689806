/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "./BaseStore";
import ProductPropertyCollection from "./ProductPropertyCollection";


export default class ProductPropertyCollectionStore extends BaseStore {
    static entityName = 'product-property-collection';

    options() {
        return {
            ...super.options(),
            model: ProductPropertyCollection,
            fetchParams: {
                fields: 'id,name',
                sort: 'name'
            }
        }
    }
}