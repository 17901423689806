<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import Draggable from 'vuedraggable';
    import filter from "lodash/filter";
    import differenceBy from "lodash/differenceBy";
    import without from "lodash/without";
    import map from "lodash/map";
    import ProductPropertyStore from "../../../models/ProductPropertyStore";

    export default {
        name: "PropertiesSelector",

        components: {
            Draggable
        },

        props: {
            /**
             * The value represent batch junction relations
             * */
            value: {
                type: Array,
                default: () => []
            }
        },


        data() {
            return {
                selectedFilterText: "",
                sourceFilterText: "",
                sourceStore: new ProductPropertyStore([], {autoLoad: true, fetchParams: {limit: 0}}),
                selectedData: this.batchToData(this.value)
            }
        },


        watch: {
            value: function (batch) {
                // calculate data array from junction relations
                this.selectedData = this.batchToData(batch);
            }
        },


        computed: {
            filteredSelectedData: function () {
                return filter(this.selectedData, m => m.name.toLowerCase().indexOf(this.selectedFilterText.toLowerCase()) > -1);
            },
            filteredSourceData: function () {
                let data = differenceBy(this.sourceStore.models, this.selectedData, 'id');
                return filter(data, m => m.name.toLowerCase().indexOf(this.sourceFilterText.toLowerCase()) > -1);
            }
        },


        methods: {
            /**
             * @param batch propertyHasCollection[] relations
             * */
            batchToData(batch) {
                return map(batch, m => ({
                    id: m.product_property_id,
                    name: m.propertyName,
                    display_order: m.display_order
                }));
            },

            /**
             * Reverse process: create batch to send on server from data
             * */
            dataToBatch(data) {
                return map(data, (m, i) => ({
                    product_property_id: m.id,
                    propertyName: m.name,
                    display_order: i + 1
                }));
            },

            /**
             * Batch compare
             * */
            isBatchEquals(b1, b2) {
                if (b1.length != b2.length) {
                    return false;
                }

                for (let i = 0; i < b1.length; i++) {
                    if (b1[i].product_property_id != b2[i].product_property_id) {
                        return false;
                    }
                }
                return true;
            },


            handleInputChange() {
                let curr = this.dataToBatch(this.selectedData);
                if (!this.isBatchEquals(curr, this.value)) {
                    this.$emit("input", curr);
                }
            },


            onDropEnd(e) {
                this.handleInputChange();
            },

            onPropRemoveClick(prop) {
                this.selectedData = without(this.selectedData, prop);
                this.handleInputChange();
            }
        }
    }
</script>


<template>
    <el-row :gutter="20">
        <el-col :span="12">
            <h3>Состав Коллекции</h3>

            <el-input v-model="selectedFilterText"
                      prefix-icon="el-icon-search"
                      placeholder="Фильтр по названию..."
                      clearable></el-input>

            <div class="migrate-list selected-list">
                <draggable v-model="selectedData"
                           group="properties"
                           @end="onDropEnd">
                    <div v-for="prop in filteredSelectedData"
                         :key="prop.id"
                         class="migrate-list__item">

                        <el-icon class="el-icon-sort"></el-icon>
                        <span class="migrate-list__item-name">{{prop.name}}</span>

                        <el-button circle
                                   @click="onPropRemoveClick(prop)"
                                   size="mini"
                                   style="border: none"
                                   icon="el-icon-close"></el-button>
                    </div>
                </draggable>


                <div class="helper-text"
                     v-if="selectedData.length < 1">
                    <p>Перетащите нужные свойства сюда.</p>
                    <p>П.С. Сортировка поддерживается ;)</p>
                </div>
            </div>
        </el-col>
        <el-col :span="12">
            <h3>Доступные свойства</h3>

            <el-input v-model="sourceFilterText"
                      prefix-icon="el-icon-search"
                      placeholder="Фильтр по названию..."
                      clearable></el-input>

            <div class="migrate-list source-list">
                <draggable :list="filteredSourceData"
                           group="properties"
                           @end="onDropEnd">
                    <div v-for="prop in filteredSourceData"
                         :key="prop.id"
                         class="migrate-list__item">
                        <span class="migrate-list__item-name">{{prop.name}}</span>
                    </div>
                </draggable>


            </div>
        </el-col>
    </el-row>
</template>


<style scoped lang="scss">
    @import "../../../scss/element";


    .migrate {
        display: flex;

        &-list {
            /*width: 50%;*/
            max-height: 50vh;
            overflow-y: auto;


            & + & {
                margin-left: 20px;
            }

            &__item {
                display: flex;
                justify-content: space-between;
                cursor: s-resize;
                padding: 5px;
                align-items: center;

                & + & {
                    border-top: 1px solid $--border-color-base;
                }

                > * + * {
                    margin-left: 10px;
                }

                &-name {
                    flex: 1;
                    font-size: .9rem;
                    line-height: 1.6rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .helper-text {
        font-size: small;
        color: silver;
        margin: 1rem;
    }
</style>