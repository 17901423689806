<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import ProductPropertyCollectionStore from "../../../models/ProductPropertyCollectionStore";
    import filter from "lodash/filter";

    export default {
        name: "Main",

        data() {
            return {
                selection: null,
                store: new ProductPropertyCollectionStore([], {
                    fetchParams: {
                        limit: 0
                    }
                }),
                filterText: ""
            }
        },

        computed: {
            models: vm => filter(vm.store.models, m => m.name.indexOf(vm.filterText) > -1)
        },


        mounted() {
            this.store.fetch();
        },


        methods: {
            onRowSelect(currentRow, oldCurrentRow) {
                this.$router.push({
                    name: 'products-collection-edit',
                    params: {
                        product_property_collection_id: currentRow.id
                    }
                });

            },

            onAddClick() {
                this.$router.push({
                    name: 'products-collection-create'
                });
            }
        }
    }
</script>


<template>
    <div v-loading="store.loading">
        <P><b>Коллекции</b> - это предопределенные наборы Характеристик</P>

        <div style="max-width: 500px">
            <el-container class="toolbar">
                <el-input prefix-icon="el-icon-search"
                          placeholder="Фильтр по названию..."
                          v-model="filterText"
                          clearable></el-input>
                <el-button type="primary"
                           @click="onAddClick">Создать
                </el-button>
            </el-container>

            <el-table :show-header="false"
                      highlight-current-row
                      @current-change="onRowSelect"
                      :data="models">
                <el-table-column prop="$.name"></el-table-column>
                <el-table-column width="35">
                    <el-icon class="el-icon-arrow-right"></el-icon>
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>


<style lang="scss">
    .toolbar {
        > * + * {
            margin-left: 10px;
        }
    }

    td {
        cursor: pointer;
    }
</style>