<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */
    import axios from "axios";
    import isEmpty from "lodash/isEmpty";

    export default {
        name: "VendorSelect",

        props: ['value'],


        data() {
            return {
                internalValue: this.value,
                options: [],
                loading: false,
            }
        },


        methods: {
            /**
             * on remote mode
             * */
            async queryOptions(query) {
                // if (!isEmpty(query)) {
                this.loading = true;
                try {
                    let response = await axios.get("admin/vendor/search", {
                        params: {
                            query: query || "",
                            fields: "id,name"
                        }
                    });
                    this.options = response.data;
                } finally {
                    this.loading = false;
                }
                // }
            },


            /**
             * fetch options
             * */
            async fetchOptions() {
                this.loading = true;
                try {
                    let response = await axios.get(`admin/vendor`, {
                        params: {
                            limit: 0,
                            fields: "id,name"
                        }
                    });
                    this.options = response.data.data;
                } finally {
                    this.loading = false;
                }
            }
        },


        watch: {
            internalValue(value) {
                this.$emit("input", value);
            }
        },


        created() {
            this.fetchOptions();
        }
    }
</script>


<template>
    <el-select v-model="internalValue"
               filterable
               clearable
               :loading="loading">
        <el-option v-for="item in options"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
        </el-option>
    </el-select>
</template>


<style scoped>
    .el-select {
        width: 100%;
    }
</style>