<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../models/Product";
    import BasicsForm from "./BasicsForm";

    export default {
        name: "CreateForm",

        components: {
            BasicsForm
        },

        data() {
            return {
                measures: Product.measures()
            }
        }
    }
</script>


<template>
    <div>
       <h1>Создать продукт</h1>

        <basics-form></basics-form>
    </div>
</template>


<style lang="scss" scoped>
    .el-page-header {
        margin-bottom: 2em;
    }
</style>