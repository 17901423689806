<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../../models/Product";
    import BasicsForm from "../BasicsForm";

    export default {
        name: "Form",

        props: {
            model: {
                type: Product,
                required: true
            }
        },

        components: {
            BasicsForm
        }
    }
</script>


<template>
    <basics-form :model="model"></basics-form>
</template>


<style scoped>

</style>