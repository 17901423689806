<script>
/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import Product from "../../models/Product";
import VendorSelect from "./VendorSelect";
import HtmlEditor from "../../components/HtmlEditor";

export default {
    name: "BasicsForm",


    components: {
        HtmlEditor,
        VendorSelect
    },


    props: {
        model: {
            type: Product,
            default: new Product()
        }
    },


    data() {
        return {
            isEdit: this.model.id,
            isChanged: false,
            measures: Product.measures()
        }
    },


    methods: {
        async save() {
            try {
                await this.model.save();
                let message = this.isEdit ? `${this.model.name} успешно обновлен` : `${this.model.name} успешно создан`;
                this.$message.success(message);
            } catch (e) {
                if (this.model.fatal) {
                    this.$message.error("Ошибка запроса");
                }
                throw e;
            }
        },
        redirectToCard() {
            this.$router.push({
                name: 'product-basic',
                params: {
                    id: this.model.id
                }
            });
        },
        onSaveCommand(command) {
            let handler;
            switch (command) {
                case 'repeat':
                    handler = () => {
                        this.model.clear();
                    };
                    break;
                case 'back':
                    handler = () => {
                        this.$router.back();
                    };
                    break;
                default:
                    handler = this.redirectToCard;
            }

            this.save().then(handler);
        },
        async onSaveClick() {
            await this.save();
            if (!this.isEdit) {
                this.redirectToCard();
            }
        }
    },


    mounted() {
        this.model.on('change', e => {
            this.isChanged = this.model.changed();
            if (this.model.measure === Product.MEASURE_KG) {
                this.model.weight_kg = 1;
            }
        });
    }
}
</script>


<template>

    <el-form label-position="top">
        <el-row :gutter="20">
            <el-col>
                <el-form-item label="Название"
                              :error="model.errors.name"
                              :show-message="!!model.errors.name"
                              required>
                    <el-input v-model="model.name"
                              placeholder="Куриное филе охлажденное"
                              clearable
                              :maxlength="250"></el-input>
                </el-form-item>
            </el-col>
            <el-col>
                <el-form-item label="Алиас (латиницей, без пробелов)"
                              :error="model.errors.alias"
                              :show-message="!!model.errors.alias"
                              required>
                    <el-input v-model="model.alias"
                              placeholder="kurinoe-file-ohlazhdenoe"
                              clearable=""
                              :maxlength="250"></el-input>
                </el-form-item>
            </el-col>
        </el-row>


        <el-row :gutter="20" type="flex" align="bottom">
            <el-col :md="6">
                <el-form-item label="Ед. измерения"
                              required
                              :error="model.errors.measure"
                              :show-message="!!model.errors.measure">

                    <el-select v-model="model.measure">
                        <el-option v-for="item in measures"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                            {{ item.label }}
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :md="6">
                <el-form-item label="Вес, кг за. ед. изм."
                              required
                              :error="model.errors.weight_kg"
                              :show-message="!!model.errors.weight_kg">
                    <el-input v-model="model.weight_kg"
                              placeholder="0.45"
                              clearable
                              :min="1"></el-input>
                </el-form-item>
            </el-col>
            <el-col :md="6">
                <el-form-item label="Цена, руб за ед. изм."
                              :error="model.errors.price"
                              required
                              :show-message="!!model.errors.price">
                    <el-input v-model="model.price"
                              placeholder="1500"
                              clearable
                              :min="1"></el-input>
                </el-form-item>
            </el-col>
            <el-col :md="6">
                <el-form-item label="Цена по акции, руб"
                              :error="model.errors.price_action"
                              :show-message="!!model.errors.price_action">
                    <el-input v-model="model.price_action"
                              placeholder="1200"
                              clearable
                              :min="0"></el-input>
                </el-form-item>
            </el-col>
        </el-row>


        <el-row :gutter="20">
            <el-col :md="6">
                <el-form-item label="Артикул"
                              :error="model.errors.internal_code"
                              :show-message="!!model.errors.internal_code">
                    <el-input v-model="model.internal_code"
                              placeholder="ФИЛ-123"
                              clearable
                              :maxlength="45"></el-input>
                </el-form-item>
            </el-col>
            <el-col :md="6">
                <el-form-item label="Штрих-код"
                              :error="model.errors.bar_code"
                              :show-message="!!model.errors.bar_code">
                    <el-input v-model="model.bar_code"
                              placeholder="123456789-1234"
                              clearable
                              :maxlength="45"></el-input>
                </el-form-item>
            </el-col>
        </el-row>


        <el-form-item label="Краткий текст превью"
                      :error="model.errors.short_text"
                      :show-message="!!model.errors.short_text"
                      required>
            <el-input type="textarea"
                      v-model="model.short_text"
                      placeholder="Данный текст будет отображаться при просмотре Продукта в каталоге"
                      :autosize="{ minRows: 3}"
                      :show-word-limit="true"
                      :maxlength="255"></el-input>
        </el-form-item>
        <el-form-item label="Полный текст, можно html"
                      :error="model.errors.full_text"
                      :show-message="!!model.errors.full_text">
            <html-editor v-model="model.full_text"
                         :editor-config="{placeholder:'Данный текст будет отображаться в карточке Продукта. Если текст не задан, вместо него будет использоваться краткий текст превью.'}"></html-editor>
        </el-form-item>


        <el-row :gutter="20">
            <el-col :md="12">
                <el-form-item label="Цех (производитель)"
                              :error="model.errors.vendor_id"
                              :show-message="!!model.errors.vendor_id">
                    <vendor-select v-model="model.vendor_id"></vendor-select>
                </el-form-item>
            </el-col>
            <el-col :md="6">
                <el-form-item label="Код производителя"
                              :error="model.errors.vendor_code"
                              :show-message="!!model.errors.vendor_code">
                    <el-input v-model="model.vendor_code"
                              placeholder="И5768"
                              clearable
                              :maxlength="45"></el-input>
                </el-form-item>
            </el-col>

            <el-col>
                <el-form-item :error="model.errors.is_active"
                              :show-message="!!model.errors.is_active">
                    <el-switch active-text="Виден на сайте"
                               v-model="model.is_active"
                               :active-value="1"
                               :inactive-value="0">
                    </el-switch>
                </el-form-item>
            </el-col>
        </el-row>


        <el-button v-if="isEdit"
                   :disabled="!isChanged"
                   v-loading="model.saving"
                   @click="onSaveClick">
            Сохранить
        </el-button>

        <el-dropdown v-if="!isEdit"
                     split-button type="primary"
                     @command="onSaveCommand"
                     v-loading="model.saving"
                     @click="onSaveClick">
            Создать
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit">Создать и перейти в карточку Продукта</el-dropdown-item>
                <el-dropdown-item command="back">Создать и вернуться назад</el-dropdown-item>
                <el-dropdown-item command="repeat">Создать и повторить</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>


    </el-form>
</template>


<style lang="scss" scoped>
.el-form {
    max-width: 800px;
    margin-bottom: 1em;
}
</style>