<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../../models/Product";

    export default {
        name: "Card",

        props: {
            id: Number | String
        },


        data() {
            return {
                model: new Product()
            }
        },


        watch: {
            $route: function (route) {
                // update data only when model`s id changed
                if (route.params.id !== this.model.id) {
                    this.prepareData();
                }
            }
        },


        methods: {
            async prepareData() {
                this.model.set("id", this.$route.params.id);
                try {
                    await this.model.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных.");
                }
            }
        },

        created() {
            // загружаем данные, когда представление создано
            // и данные реактивно отслеживаются
            this.prepareData();
        },
    }
</script>


<template>
    <div v-loading="model.loading">
        <h1> {{model.$.name}} </h1>

        <div style="padding: 0px 20px 20px 0px">
            <p class="metas">Дата последней модификации: {{model.$.updated_at|datetime}}</p>


            <el-menu :router="true" :default-active="$route.name" mode="horizontal">
                <el-menu-item index="product-basic" :route="{name:'product-basic',params:{model}}">
                    Основное
                </el-menu-item>
                <el-menu-item index="product-images" :route="{name:'product-images', params:{model}}">
                    Картинки
                </el-menu-item>
                <el-menu-item index="product-properties" :route="{name:'product-properties', params:{model}}">
                    Характеристики
                </el-menu-item>
                <el-menu-item index="product-categories" :route="{name:'product-categories', params:{model}}">
                    Витрина
                </el-menu-item>
            </el-menu>

            <div class="humanize-container">
            <router-view :model="model"></router-view>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    h1 {
        margin-bottom: 0;
    }

    .metas {
        margin-top: 0;
        font-size: smaller;
        color: silver;
    }

    .el-menu {
        margin: 20px 0 30px;
    }
</style>