/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import BaseStore from "./BaseStore";
import ProductCategoryImage from "./ProductCategoryImage";
import ProductCategoryHasCategory from "./ProductCategoryHasCategory";
import merge from "lodash/merge";

export default class ProductCategory extends BaseModel {
    static entityName = 'product-category';

    defaults() {
        return {
            ...super.defaults(),
            name: null,
            alias: null,
            icon_cls: null,
            short_text: '',
            full_text: null,
            is_active: 1,
            created_at: null,
            updated_at: null,
            images: [],
            parent_id: null,
            display_order: null
        }
    }

    routes() {
        return {
            ...super.routes(),
            'link-parents': '{urlPrefix}/{entityName}/link-parents',
            'tree': '{urlPrefix}/{entityName}/tree',
        }
    }


    /**
     * @return BaseStore
     * */
    getImages(options = {}) {
        return new BaseStore(this.images, {
            ...options,
            model: ProductCategoryImage,
            fetchParams: {
                expand: 'thumbUrl',
                sort: 'display_order',
                filter: JSON.stringify([{
                    property: "product_category_id",
                    value: this.id
                }])
            }
        });
    }

    /**
     * @return BaseStore - The configured store of parent refs
     * */
    getHasParents(options = {}) {
        if (!this._hasParents) {
            this._hasParents = new BaseStore([], {
                model: ProductCategoryHasCategory,
                ...merge({
                    fetchParams: {
                        sort: 'display_order',
                        filter: JSON.stringify([{
                            property: "child_category_id",
                            value: this.id
                        }])
                    }
                }, options)
            });
        }

        return this._hasParents;
    }

    /**
     * Send request to link ids as parents
     * */
    async linkParents(ids = []) {
        let method = 'POST';
        let url = this.getURL(this.getRoute('link-parents'), this.getRouteParameters());
        let data = {ids};
        let params = {
            id: this.id,
            expand: 'parentCategoryName',
            limit: 0
        };

        return this.createRequest({method, url, data, params}).send()
            .then((resp) => {
                this.getHasParents().add(resp.getData("data").data);
            });
    }

    /**
     * @param query string - The search signature
     * @param includedIds array
     * @param excludedIds array
     * @param options Object - The extra options for Store instance
     * @return BaseStore
     * */
    static getAvailable(query = '', includedIds = [], excludedIds = [], options = {}) {
        let filters = [];
        if (query && query.length > 0) {
            filters.push({
                property: 'name',
                operator: 'like',
                value: query
            });
        }
        if (excludedIds && excludedIds.length > 0) {
            filters.push({
                property: 'id',
                operator: 'not in',
                value: excludedIds
            });
        }
        if (includedIds && includedIds.length > 0) {
            filters.push({
                property: 'id',
                operator: 'in',
                value: includedIds
            });
        }

        return new BaseStore([], {
            model: ProductCategory,
            ...merge({
                filters: filters,
                fetchParams: {
                    sort: 'name'
                }
            }, options)
        });
    }

    /**
     * Do request tree data
     * */
    async fetchTree(filterText = '') {
        let method = 'GET';
        let url = this.getURL(this.getRoute('tree'), this.getRouteParameters());
        let data = null;
        let params = {
            id: this.id
        };

        return this.createRequest({method, url, data, params}).send()
            .then((resp) => resp.getData());
    }
}
