/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class ProductPropertyHasCollection extends BaseModel {
    static entityName = 'product-property-has-collection';

    defaults() {
        return {
            product_property_id: null,
            product_property_collection_id: null,
            display_order: 1
        }
    }
}
