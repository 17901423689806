/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseStore from "./BaseStore";
import ProductPropertyHasCollection from "./ProductPropertyHasCollection";


export default class ProductPropertyHasCollectionStore extends BaseStore {
    static entityName = 'product-property-has-collection';

    options() {
        return {
            ...super.options(),
            model: ProductPropertyHasCollection,
            fetchParams: {
                sort: 'display_order',
                expand: 'propertyName'
            }
        }
    }
}