<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../../models/Product";
    import axios from "axios";
    import pull from "lodash/pull";
    import Draggable from 'vuedraggable'
    import Formatter from "../../../utils/Formatter";

    export default {
        name: "Images",

        props: {
            model: {
                type: Product,
                required: true
            }
        },

        components: {
            Draggable
        },


        data() {
            return {
                maxUploadSize: Formatter.asUnit(process.env.VUE_APP_MAX_UPLOAD_MB, {unit:'megabyte'}),
                images: [],
                isLoading: false,
                uploadHandler: `${axios.defaults.baseURL}/admin/product-image/upload?expand=thumbUrl`,
                uploadHeaders: axios.defaults.headers.common
            }
        },

        watch: {
            $route: 'fetchImages'
        },


        methods: {
            async fetchImages() {
                try {
                    this.isLoading = true;
                    let res = await axios("/admin/product-image", {
                        params: {
                            expand: 'thumbUrl',
                            sort: 'display_order',
                            filter: JSON.stringify([{
                                property: "product_id",
                                value: this.model.id
                            }])
                        }
                    });
                    this.images = res.data.data;
                } catch (e) {
                    this.$message.error("Ошибка загрузки данных");
                } finally {
                    this.isLoading = false;
                }
            },


            beforeUpload(file) {
                this.isLoading = true;
            },

            onError(err, file, fileList) {
                this.$message.error(`Ошибка загрузки изображения ${file.name}`);
                this.isLoading = false;
            },

            onSuccess(image) {
                this.$message.success(`Успешно загружено ${image.src}`);
                this.images.push(image);
                this.isLoading = false;
            },

            async onRemoveClick(image) {
                this.isLoading = true;

                try {
                    await axios.delete(`/admin/product-image/${image.id}`);
                    this.images = pull(this.images, image);
                    this.$message.success(`Успешно удалено ${image.src}`);
                } catch (e) {
                    this.$message.error(`Ошибка удаления ${image.src}`);
                } finally {
                    this.isLoading = false;
                }
            },


            onDragMove(e) {
                // prevent dragging the uploader
                if (e.dragged.classList.contains("uploader")) {
                    return false;
                }

            },

            async onDragEnd(e) {
                // no changes
                if (e.newIndex == e.oldIndex) {
                    return;
                }

                let image = this.images[e.newIndex];
                this.isLoading = true;
                try {
                    await axios.put(`/admin/product-image/${image.id}`, {
                        display_order: e.newIndex + 1
                    });
                    this.$message.success(`Порядок изменен ${image.src}`);

                    this.images = [];
                    this.fetchImages();
                } finally {
                    this.isLoading = false;
                }
            }
        },


        mounted() {
            this.fetchImages();
        }
    }
</script>


<template>
    <div v-loading="isLoading"
         class="product-images">


        <draggable class="images-list" v-model="images"
                   :move="onDragMove"
                   @end="onDragEnd">
            <div class="image-item"
                 v-for="image in images"
                 :key="image.id">
                <el-image :key="image.src"
                          :src="image.thumbUrl"
                          fit="cover"
                          :lazy="false">{{image.src}}
                </el-image>
                <div class="image-toolbar">
                    <div class="image-toolbar__tool">
                        <el-popconfirm confirm-button-text="Да"
                                       cancel-button-text="Отмена"
                                       title="Удалить изображение?"
                                       @confirm="onRemoveClick(image)">
                            <el-button slot="reference" circle
                                       type="danger"
                                       icon="el-icon-delete"></el-button>
                        </el-popconfirm>
                    </div>
                </div>
            </div>
            <el-upload class="uploader"
                       :action="uploadHandler"
                       :data="{product_id: model.id}"
                       :on-error="onError"
                       :on-success="onSuccess"
                       :headers="uploadHeaders"
                       :show-file-list="false"
                       :before-upload="beforeUpload"
                       drag
                       name="imageFile"
                       accept="image/jpeg">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    <small>Перетащите файлы сюда <em>или кликните</em><br>
                        jpg не более {{maxUploadSize}}
                    </small>
                </div>
            </el-upload>
        </draggable>


    </div>
</template>


<style lang="scss" scoped>
    $width: 180px;
    $height: 180px;


    .images-list {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 auto;


        > * {
            margin: 10px;
        }
    }

    .image-item {
        box-shadow: 0 0 5px silver;
        background-color: #fff;
        position: relative;

        &, .el-image {
            width: $width;
            height: $height;
        }

        &:hover {
            .image-toolbar {
                display: block;
            }
        }
    }

    .image-toolbar {
        position: absolute;
        display: none;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 5px;
        width: 100%;
        line-height: $height;
        text-align: center;
        background-color: rgba(0, 0, 0, .4);
    }

    .uploader {
        width: $width;
        height: $height;
        margin: 10px;
    }
</style>