<script>
/**
 * The list of Products
 *
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import compact from "lodash/compact";
import debounce from "lodash/debounce";
import Observer from 'vue-intersection-observer'
import Product from "../../models/Product";
import BaseStore from "../../models/BaseStore";
import ListFilters from "@/views/product/ListFilters";


export default {
  name: "ProductList",

  components: {
    ListFilters,
    Observer
  },


  data() {
    return {
      store: new BaseStore([], {
        model: Product,
        fetchParams: {
          autoFilter: true,
          sort: '-updated_at',
          fields: 'id,name,price,internal_code,vendor_code,vendor_id,is_active',
          expand: 'thumbUrl,vendorText',
          filterText: ""
        }
      }),
      selectedModels: [],
      filterText: "",
      needData: null
    }
  },


  computed: {
    modelsData: (vm) => vm.store.models
  },


  methods: {
    onObserveChange(entry, unobserve) {
      if (entry.isIntersecting) {
        this.prepareData();
      }
    },

    async prepareData(opts) {
      if (this.store.loading) {
        return false;
      }

      if (this.store.isPaginated()) {
        if (this.store.isLastPage()) {
          return false;
        }
      } else {
        this.store.page(1);
      }

      try {
        await this.store.fetch(opts);
      } catch (e) {
        this.$message.error("Ошибка загрузки данных");
      }
    },


    getProductMetaString(product) {
      return compact([
        product.internal_code && `Артикул: ${product.internal_code}`,
        // product.vendor_code && `Код производителя: ${product.vendor_code}`,
        product.bar_code && `Штрих код: ${product.bar_code}`,
      ]).join(", ");
    },


    getRowStyle(rowScope, rowIndex) {
      let model = rowScope.row;
      return !model.is_active ? {opacity: 0.4} : "";
    },


    handleSelectionChange(val) {
      this.selectedModels = val;
    },


    onSetVisibleClick() {
      this.selectedModels.map((model) => {
        model.is_active = true;
        model.save()
            .then(() => {
              this.$notify.success(`${model.name} успешно изменен`);
            });
      });

      // this.$refs.list.clearSelection();
    },


    onSetInvisibleClick() {
      this.selectedModels.map((model) => {
        model.is_active = false;
        model.save()
            .then(() => {
              this.$notify.success(`${model.name} успешно изменен`);
            });
      });

      // this.$refs.list.clearSelection();
    },


    onDeleteClick() {
      this.selectedModels.map((model) => {
        model.delete();
      });
    },

    onRefreshClick() {
      this.store.reload({
        params: {
          filterText: this.filterText
        }
      });
    },


    onAddClick() {
      this.$router.push({name: "product-create"});
    }
  },


  created() {
    /**
     * While input bind to store.filterText
     * reload the store cause new request with new filterText value
     * */
    this.onFilterChange = debounce(this.onRefreshClick, 500);
  }
}
</script>


<template>
  <div class="products-list humanize-container--long">
    <div class="toolbar humanize-container" v-if="!selectedModels.length">
      <el-input placeholder="Название, артикул, код ..."
                clearable
                @input="onFilterChange"
                v-model="filterText">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <div class="toolbar-item">
        <el-tooltip content="Добавить новый продукт" class="toolbar-item">
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="onAddClick">Создать
          </el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-item">
        <el-tooltip content="Обновить данные" class="toolbar-item">
          <el-button icon="el-icon-refresh"
                     @click="onRefreshClick"></el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-item">
        <list-filters :store="store"></list-filters>
      </div>
    </div>

    <div class="toolbar alternate" v-if="selectedModels.length > 0">
      <div class="toolbar-item">Выбрано {{ selectedModels.length }} продуктов:</div>
      <div class="toolbar-item">
        <el-tooltip content="Продукты будут видны в каталоге витрины и доступны для заказа покупателем"
                    class="toolbar-item">
          <el-button type="default"
                     icon="el-icon-view"
                     @click="onSetVisibleClick">Показать
          </el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-item">
        <el-tooltip content="Продукты будут не видны в каталоге витрины и не доступны для заказа покупателем"
                    class="toolbar-item">
          <el-button @click="onSetInvisibleClick"
                     icon="el-icon-document-delete">Скрыть
          </el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-item">
        <el-popconfirm
            confirmButtonText='Удалить'
            cancelButtonText='Отмена'
            confirmButtonType="danger"
            icon="el-icon-info"
            iconColor="red"
            class="toolbar-item"
            :title="`Будут удалены ${selectedModels.length} продуктов. Уверены что хотите продолжить?`"
            @confirm="onDeleteClick">
          <el-tooltip slot="reference"
                      content="Удалить выбранные продукты из базы данных">
            <el-button type="danger"
                       icon="el-icon-delete"></el-button>
          </el-tooltip>
        </el-popconfirm>
      </div>
    </div>


    <el-table ref="list"
              :data="modelsData"
              :show-header="true"
              :row-style="getRowStyle"
              @selection-change="handleSelectionChange"
              width="100%">

      <el-table-column
          type="selection"
          width="50">
      </el-table-column>

      <el-table-column width="70">
        <template v-slot="slotProps">
          <el-image class="thumb"
                    :src="slotProps.row.thumbUrl"
                    fit="cover"></el-image>
        </template>
      </el-table-column>

      <el-table-column label="Название">
        <template v-slot="slotProps">
          <router-link class="product-name"
                       :to="{
                                name: 'product-basic',
                                params: {
                                    id: slotProps.row.id
                                }
                            }">{{ slotProps.row.name }}
          </router-link>
          <div class="metas">{{ getProductMetaString(slotProps.row) }}</div>
        </template>
      </el-table-column>

      <el-table-column label="Цех / Производитель"
                       width="200">
        <template v-slot="slotProps">
          <div v-if="!!slotProps.row.vendor_id">
            <router-link class="vendor-name"
                         :to="{
                                name: 'vendor-edit',
                                params: {
                                    id: slotProps.row.vendor_id
                                }
                            }">{{ slotProps.row.vendorText }}
            </router-link>
            <div class="metas">{{ slotProps.row.vendor_code || "" }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Цена"
                       width="150"
                       align="right">
        <template v-slot="slotProps">
                    <span class="price">
                    {{ slotProps.row.price | currency }}
                    </span>
        </template>
      </el-table-column>
    </el-table>

    <observer @on-change="onObserveChange">
      <p v-loading="true" v-if="store.loading">Загрузка...</p>
    </observer>
  </div>
</template>


<style lang="scss" scoped>
@import "../../scss/element";

.thumb {
  width: 50px;
  height: 50px;
}

.price {
  white-space: nowrap;
  font-weight: 600;
}

.product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
  display: block;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: $--color-primary;
  text-decoration: none;
}

.metas {
  font-size: .8rem;
  color: gray;
}
</style>