/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";
import ProductPropertyHasCollectionStore from "./ProductPropertyHasCollectionStore";

export default class ProductPropertyCollection extends BaseModel {
    static entityName = 'product-property-collection';

    defaults() {
        return {
            ...super.defaults(),
            name: null,
            batchProperties: []
        }
    }


    /**
     * @return ProductPropertyHasCollectionStore - The configured store
     * */
    getHasProperties(options) {
        return new ProductPropertyHasCollectionStore([], {
            ...options,
            filters: [
                {
                    property: 'product_property_collection_id',
                    value: this.id
                }
            ]
        });
    }


    mutations() {
        return {
            ...super.mutations(),
            // mutate array of strings to ints
            properties_ids: arr => arr.map(v => parseInt(v))
        };
    }
}
