/**
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */
import BaseModel from "./BaseModel";

export default class Product extends BaseModel {
    static entityName = 'vendor';

    defaults() {
        return {
            id: null,
            name: null,
            alias: null,
            full_text: null,
            updated_at: null
        }
    }
}
