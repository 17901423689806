<script>
    /**
     * @author Evgeny Shevtsov, info@sitespring.ru
     * @homepage http://sitespring.ru
     * @licence Proprietary
     */

    import Product from "../../../models/Product";
    import ProductPropertyStore from "../../../models/ProductPropertyStore";
    import ProductPropertyCollectionStore from "../../../models/ProductPropertyCollectionStore";
    import Draggable from "vuedraggable";
    import map from "lodash/map";
    import forEach from "lodash/forEach";
    import differenceWith from "lodash/differenceWith";
    import filter from "lodash/filter";
    import ProductPropertyHasCollectionStore from "../../../models/ProductPropertyHasCollectionStore";

    export default {
        name: "Properties",

        components: {
            Draggable
        },


        props: {
            model: {
                type: Product,
                required: true
            }
        },


        data() {
            return {
                selectedProperties: [],
                store: this.model.getProperties(),
                propertiesStore: new ProductPropertyStore([], {
                    autoLoad: true,
                    fetchParams: {
                        limit: 0
                    }
                }),
                collectionsStore: new ProductPropertyCollectionStore([], {
                    autoLoad: true,
                    fetchParams: {
                        limit: 0
                    }
                }),
                filterText: '',
                isDialogOpened: false,
                selectedProps: [],
                selectedCollections: [],
                dialogTitle: '',
                mode: null,
            }
        },


        watch: {
            $route: function () {
                // update props store
                this.model.fetchProperties();
            }
        },

        computed: {
            currentRelationsFiltered: function () {
                return filter(this.store.models, m => m.propertyName.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1);
            },
            availPropsFiltered: function () {
                return differenceWith(this.propertiesStore.models, this.store.models, (prop, relation) => (prop.id === relation.product_property_id));
            }
        },


        methods: {
            onAddCommand(cmd) {
                this.selectedProps = [];
                this.selectedCollections = [];
                this.isDialogOpened = true;
                this.mode = cmd;
            },


            onAddFromPropsClick() {
                forEach(this.selectedProps, this.addRelationById);
                this.isDialogOpened = false;
            },


            async onAddFromCollectionsClick() {
                let collection_ids = this.selectedCollections;
                let hasPropsStore = new ProductPropertyHasCollectionStore([], {
                    filters: [
                        {
                            property: 'product_property_collection_id',
                            value: collection_ids
                        }
                    ],
                    fetchParams: {
                        limit: 0
                    }
                });
                try {
                    await hasPropsStore.fetch();
                } catch (e) {
                    this.$message.error("Ошибка загрузки даннных коллекции");
                }

                forEach(hasPropsStore.models, relation => {
                    if (!this.store.find({product_property_id: relation.product_property_id})) {
                        this.store.add(relation);
                    }
                });

                this.isDialogOpened = false;
            },


            onEditPropClick(relation) {
                this.$prompt('', relation.propertyName, {
                    confirmButtonText: 'Применить',
                    cancelButtonText: 'Отмена',
                    inputValue: relation.value,
                    inputPlaceholder: 'Введите значение...'
                }).then(({value}) => {
                    relation.set("value", value);
                })
            },


            async onSaveClick() {
                let batch = map(this.store.models, (m, i) => {
                    return {
                        display_order: i + 1,
                        product_property_id: m.product_property_id,
                        value: m.value || "",
                        group_name: m.group_name,
                        propertyName: m.propertyName
                    }
                });

                try {
                    await this.model.setProperties(batch);
                    this.$message.success("Характеристики успешно сохранены");
                } catch (e) {
                    this.$message.error("Ошибка сохранения данных");
                }
            },


            onRemoveRelationClick(relation) {
                this.store.remove(relation);
            },


            addRelationById(propId) {
                let hasProperties = this.store;
                let prop = this.propertiesStore.find({id: propId});
                hasProperties.add({
                    product_id: this.model.id,
                    product_property_id: propId,
                    propertyName: prop.name,
                    value: ""
                });
            }
        }
    }
</script>


<template>
    <div v-loading="store.loading"
         class="content">

        <el-container class="toolbar">
            <el-input prefix-icon="el-icon-search"
                      placeholder="Фильтр по названию..."
                      v-model="filterText"
                      clearable></el-input>

            <el-dropdown split-button
                         type="primary"
                         @click="onAddCommand('byProperty')"
                         @command="onAddCommand">Добавить
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="byProperty" icon="el-icon-tickets">Добавить характеристики</el-dropdown-item>
                    <el-dropdown-item command="byCollection" icon="el-icon-receiving">Сформировать из коллекции</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </el-container>

        <draggable class="propslist"
                   :list="store.models">
            <div v-for="relation in currentRelationsFiltered"
                 class="propslist-item">
                <div class="prop-name">
                    <el-icon class="el-icon-sort"></el-icon>
                    <span class="prop-name__text">{{relation.propertyName}}</span>
                    <el-button circle
                               icon="el-icon-close"
                               size="mini"
                               @click="onRemoveRelationClick(relation)"></el-button>
                </div>
                <div class="prop-value">
                    <span class="prop-value__text">{{relation.value || 'не задано'}}</span>
                    <el-button circle
                               icon="el-icon-edit"
                               size="mini"
                               @click="onEditPropClick(relation)"></el-button>
                </div>
            </div>
        </draggable>
        <p class="empty-text" v-if="!store.models.length">Характеристик не задано...</p>


        <el-dialog :visible.sync="isDialogOpened"
                   :title="mode == 'byProperty' ? 'Добавить характеристики' : 'Сформировать из коллекции'">
            <div v-if="mode=='byProperty'">
                <el-select v-model="selectedProps"
                           clearable
                           filterable
                           multiple
                           placeholder="Название...">
                    <el-option v-for="property in availPropsFiltered"
                               :key="property.id"
                               :label="property.name"
                               :value="property.id">
                    </el-option>
                </el-select>
                <p>
                    <el-button @click="onAddFromPropsClick">Добавить</el-button>
                </p>
            </div>
            <div v-if="mode=='byCollection'">
                <el-select v-model="selectedCollections"
                           clearable
                           filterable
                           multiple
                           placeholder="Название...">
                    <el-option v-for="collection in collectionsStore.models"
                               :key="collection.id"
                               :label="collection.name"
                               :value="collection.id">
                    </el-option>
                </el-select>
                <p>
                    <el-button @click="onAddFromCollectionsClick">Сформировать</el-button>
                </p>
            </div>
        </el-dialog>


        <p>
            <el-button @click="onSaveClick" type="">Сохранить</el-button>
        </p>
    </div>
</template>


<style scoped lang="scss">
    .el-select {
        width: 100%;
    }

    .content {
        max-width: 600px;
    }

    .toolbar {
        > * + * {
            margin-left: 10px;
        }
    }

    .empty-text {
        font-size: smaller;
        color: gray;
    }

    .propslist {
        &-item {
            cursor: pointer;
            display: flex;
            padding: 10px 0;
            flex: 0 0 50%;

            & + & {
                border-top: 1px solid silver;
            }

            .prop-name, .prop-value {
                flex: 1;
                display: flex;
                align-items: center;

                > * + * {
                    margin-left: 10px;
                }

                &__text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    flex: 1;
                    cursor: s-resize;
                }
            }

            .prop-value {
                margin-left: 20px;
            }

            .el-button {
                border: 0;
            }
        }
    }

</style>